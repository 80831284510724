import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Hero2 from "../components/Hero2"
import LinkButton from "../components/LinkButton"
import H1 from "../components/H1"
import H2 from "../components/H2"
import P from "../components/P"
import Container from "../components/Container"
import Section from "../components/Section"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import contact_rocket from "../images/contact_rocket.svg"
import Contact from "../components/Contact"
import ImageBlock from "../components/ImageBlock"
import ContactForm from "../components/Contact-Form"
import { graphql } from "gatsby"

const meta = {
  title: "Kontakt",
  description: `Bereit Ihr Projekt mit codeflow zu starten? Wir stehen für Fragen offen. Kontaktieren Sie uns.`,
  keywords: "webagentur, kontakt, hilfe, fragen",
}

const KontaktPage = ({ data }) => {
  console.log(data)

  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    position: {
      position: "relative",
    },
  }))
  const classes = useStyles()

  return (
    <Layout>
      <SEO
        title={meta.title}
        description={meta.description}
        keywords={meta.keywords}
      />

      <Hero2 height={"100vh"}>
        <Container>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6} className={classes.position}>
              <H1>Kontakt</H1>
              <P>
                Bereit Ihr Projekt ins Web zu befördern? Wir stehen Ihnen zur
                Seite. Kontaktieren Sie uns unverbindlich für allfällige Fragen.
              </P>

              <LinkButton to="/kontakt#ready">Zum Kontaktformular</LinkButton>
            </Grid>
          </Grid>
        </Container>
        <ImageBlock
          alt="Webentwicklung"
          src={contact_rocket}
          width={"640px"}
          widthSm={"960px"}
          widthMd={"800px"}
          widthLg={"50%"}
          position={{
            position: "absolute",
            bottom: "-5%",
            right: "-120px",
            zIndex: "-1",
          }}
          positionMd={{
            position: "absolute",
            bottom: "0%",
            right: "-220px",
            zIndex: "-1",
          }}
          positionLg={{
            position: "absolute",
            bottom: "0",
            right: "-220px",
            zIndex: "-1",
          }}
        />
      </Hero2>

      <Section id="ready" marginBottomMd={"80px"}>
        <Container>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              <H2>Kontaktformular</H2>
              <P>
                Nutzen Sie unser Kontaktformular um ein kostenloses Erstgespräch
                zu vereinbaren. Wir kümmern uns um Ihre Anliegen rund ums Thema
                Web.
              </P>
            </Grid>
            <Grid item xs={12} md={6}>
              <Contact image={data.image1.childImageSharp.fluid} />
            </Grid>

            <Grid item xs={12} md={6} className={classes.position}>
              <ContactForm />
            </Grid>
          </Grid>
        </Container>
      </Section>
    </Layout>
  )
}

export default KontaktPage

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "haris_bjelic.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 700, quality: 40) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
